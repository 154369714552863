.user-card-small {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -12px;
  z-index: 1;

  .user-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url("../../../assets/user-icon-square.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
  }

  .user-default-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    background-image: url("../../../assets/user-icon-square.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
}

.user-card-small:hover {
  z-index: 2;
}
