.submission-container {
  position: relative;
  width: 100%;
  max-width: 1050px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 120px;
  border-radius: 4px;
  min-height: 1200px;

  .popup-content {

    h2 {
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 20px !important;
    }

    .boost-popup-content {
      height: 90px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 30px;
  
      .boost-popup-icon {
        position: relative;
        width: 50px;
        height: 50px;
        background-image: url("../../assets/boost-rocket.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        margin-right: 15px;
        margin-top: -5px;
        border-radius: 50%;
        background-color: #0090F2;
        
        span {
          position: absolute;
          font-size: 12px;
          width: 200px;
          top: 50px;
          left: 37px;
          color: #888888;
        }
      }
  
      input {
        width: 150px;
        margin-left: 15px;
        font-size: 20px;
        margin: 0;
      }

      button {
        margin-left: 10px;
        height: 40px;
      }
    }

  }

  .submission-content {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;

    @media only screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }

    b {
      margin-bottom: 10px;
    }

    .submission-content-section {
      position: relative;
      width: 50%;
      min-height: 100%;
      height: auto;
      padding: 15px 30px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      input {
        font-size: 16px;
        border: 1px solid #ddd;
      }
    }

    .section-left {
      width: 45%;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      video {
        border-radius: 8px;
      }

      .boost-button {
        width: 100%;
        height: 50px;
        background-color: #0090F2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        border-radius: 8px;
        cursor: pointer;

        .boost-button-icon {
          position: relative;
          width: 40px;
          height: 40px;
          background-image: url("../../assets/boost-rocket.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
        }

        .boost-button-text {
          position: relative;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .section-right {
      width: 55%;

      .submission-header {
        position: relative;
        background-color: #BED962;
        width: 100%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;

        .total-boost-points {
          position: relative;
          background-color: #fff;
          right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          height: 30px;
          padding: 0px 15px;

          .boost-icon {
            position: relative;
            width: 20px;
            height: 20px;
            background-image: url("../../assets/boost-rocket-blue.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
          }
  
          .boost-points {
            position: relative;
            color: #0f0f12;
            font-size: 14px;
            font-weight: 600;
            margin-left: 10px;
          }
        }

        h3 {
          position: absolute;
          font-size: 14px;
          margin: 0px;
          background-color: #0f0f12;
          color: #fff;
          padding: 7px 18px;
          display: inline-block;
          border-radius: 30px;
          margin-left: 6px;
          left: 1px;
          cursor: pointer;
          &:hover {
            color: #3262db;
            background-color: #fff;
          }
        }

        .edit-submission-button {
          position: relative;
          width: 30px;
          height: 30px;
          background-color: #0f0f12;
          margin-right: 6px;
          border-radius: 50%;
          cursor: pointer;
          background-image: url("../../assets/edit-icon.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
          &:hover {
            background-color: #0090F2;
          }
        }
      }

      h1 {
        font-size: 25px;
        margin-bottom: 5px;
      }

      span.updated-at {
        font-size: 14px;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
      }

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      .submission-desc {
        position: relative;
        margin-top: 30px;
        text-align: left;
        line-height: 20px;
      }

      .submission-links, .supporting-documents {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #ddd;

        
        ul.link-list {
          position: relative;
          width: 100%;
          height: auto;
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          li {
            position: relative;
            width: 100%;
            height: 30px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            
            .link-key {
              font-weight: 600;
              margin-right: 30px;
            }

            a.link-value {
              text-decoration: unset;
              color: #3262db
            }

            a.link-value:hover {
              text-decoration: underline;
            }

          }

        }

        button {
          margin-top: 15px;
        }
      }

      .submission-team {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #ddd;

        ul.team-list {
          position: relative;
          width: 100%;
          height: auto;
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          li {
            position: relative;
            width: 100%;
            height: 30px;
            border-radius: 4px;
            margin-bottom: 10px;
            font-size: 16px;
            background-color: #fff;
            border: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            box-sizing: border-box;
          }

        }

        button {
          margin-top: 15px;
        }
      }
    }
  }

}