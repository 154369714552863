body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
  background-color: #fff;
}

textarea {
  font-family: "Roboto", sans-serif;
}

