.list-submissions-results-container {
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 0px 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  margin-top: 60px;

  ul {
    position: relative;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;

    li {
      position: relative;
      height: 70px;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 20px;
      box-sizing: border-box;
      margin-bottom: 10px;

      .ribben-icon {
        position: absolute;
        width: 35px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
        margin-left: -2px;
        @media (max-width: 900px) {
          top: 18px;
        }
      }

      .first-place {
        background-image: url("../../../assets/first-place.png");
      }

      .second-place {
        background-image: url("../../../assets/second-place.png");
      }

      .third-place {
        background-image: url("../../../assets/third-place.png");
      }

      @media (max-width: 900px) {
        height: 160px;
      }

      .submission-icon {
        position: relative;
        width: 50px;
        height: 50px;
        background-color: #eee;
        background-image: url();
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .submission-name {
        position: absolute;
        font-size: 16px;
        font-weight: 600;
        left: 0px;
        height: 100%;
        width: 100%;
        cursor: pointer;
        text-align: left;
        padding-left: 65px;
        padding-top: 48px;
        z-index: 1;
      }
      
      @media (max-width: 900px) {
        .mobile-submission-name {
          padding-left: 20px;
        }
      }

      .submission-users {
        position: absolute;
        height: 100%;
        right: 180px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 2;
        @media (max-width: 900px) {
          justify-content: flex-start;
          right: unset;
          left: 40px;
          top: 40px;
        }
      }

      .submission-points {
        position: absolute;
        right: 80px;
        font-size: 20px;
        font-weight: 600;
        @media (max-width: 900px) {
          top: 50px;
          background-color: #fff;
          padding-left: 5px;
          right: unset;
          left: 60px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }

      @media (max-width: 900px) {
        .mobile-submission-points {
          left: 15px;
        }
      }

      .boost-button {
        position: absolute;
        width: 50px;
        height: 50px;
        right: 10px;
        border-radius: 8px;
        background-color: #0090F2;
        background-image: url("../../../assets/boost-rocket.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 35%;
        cursor: pointer;
        z-index: 2;
        @media (max-width: 900px) {
          top: 10px;
        }
      }
    }

    li:hover {
      border: 1px solid #0090F2;
    }
  }

  .popup-content {
    h2 {
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      padding: 0;
      text-align: center !important;
      font-size: 20px !important;
    }

    .boost-popup-content {
      height: 90px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 30px;
  
      .boost-popup-icon {
        position: relative;
        width: 50px;
        height: 50px;
        background-image: url("../../../assets/boost-rocket.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        margin-right: 15px;
        margin-top: -5px;
        border-radius: 50%;
        background-color: #0090F2;
        span {
          position: absolute;
          font-size: 12px;
          width: 200px;
          top: 50px;
          left: 37px;
          color: #888888;
        }
      }
  
      input {
        width: 150px;
        margin-left: 15px;
        font-size: 20px;
        margin: 0;
      }

      button {
        margin-left: 10px;
        height: 40px;
      }
    }

  }
  
  h3 {
    margin-bottom: 28px;
  }
}
