.sponsor-configuration-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  background-color: #f9f9f9;

  .sponsor-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .sponsor-logo {
    
    img {
      width: 180px;
      height: 60px;
      object-fit: contain;
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }

    input[type='file'] {
      font-size: 12px;
    }
  }

  .sponsor-details {
    display: flex;
    flex-direction: column;
    width: 100%;

    input[type='text'] {
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
      font-size: 14px;
      box-sizing: border-box;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sponsor-actions {
    display: flex;
    justify-content: flex-end;  // Aligns actions to the right
    gap: 10px;  // Space between buttons
    margin-top: 10px;

    .sponsor-action {
      &.delete {
        background-color: #b1afaf;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;
        margin-left: 5px;
        height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
    
        &:hover {
          background-color: #838383;
        }
      }

    }
  }

  .sponsor-action.add {
    position: absolute;
    display: block;
    width: 100%;
    padding: 10px 0px;
    left: 0px;
    background-color: #878c91;
    color: #fff;
    border: none;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    
  
    &:hover {
      background-color: #777d83;
    }
  }
}
