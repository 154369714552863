.prizes-container {
  border: 1px solid #ccc;
  // padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.prize {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;

  input {
    width: 38%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
    height: 25px;
  }

  .delete-prize-button {
    background-color: #b1afaf;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 5px;
    height: 27px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #838383;
    }
  }
}

.prize:nth-of-type(1) {
  padding-top: 10px;
}

.add-prize-button {
  display: block;
  width: 100%;
  padding: 10px 0px;
  margin-top: 10px;
  background-color: #878c91;
  color: #fff;
  border: none;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  

  &:hover {
    background-color: #777d83;
  }
}
