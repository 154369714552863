.edit-competition-container {
  position: relative;
  width: 90%;
  max-width: 850px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 30px;
  background-color: #fff;
  border-radius: 4px;
  

  .edit-competition-container-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    max-width: 550px;
    margin-top: 30px;

    .edit-comp-logo-container {
      position: relative;
      width: 90%;
      height: 50px;
      margin-top: 20px;
    }

    video {
      width: 90%;
      max-width: 800px;
      height: 320px;
      margin-top: 30px;
      border-radius: 4px;
      background-color: #000;
      margin-bottom: 30px;
    }

    .replace-video-button {
      margin-bottom: 60px;
    }

    textarea {
      height: 200px;
    }

    .admin-danger-zone {
      position: relative;
      width: 90%;
      height: 100px;
      background-color: #fff;
      border: 1px solid #d87575;
      border-radius: 4px;
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        color: #d87575;
        margin-right: 30px;
      }
    }
  }

  .toggle-button {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 180px;
    position: relative;
    left: 15px;
    top: -33px;

    input[type="checkbox"] {
      display: none;

      &:checked + .switch .slider {
        background-color: #4caf50;

        &:before {
          transform: translateX(26px);
        }
      }
    }

    .switch {
      position: relative;
      width: 60px;
      height: 34px;
      left: -70px;

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 34px;
        

        &:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }

    .toggle-text {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      left: 80px;
      top: 9px;
      text-align: left;
      width: 200px;
      text-transform: uppercase;;
    }
  }
}

.popup-container {
  .popup-content {
    .confirm-delete-popup, .confirm-replace-video-popup {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h3 {
        max-width: 90%;
      }

      b {
        position: relative;
        margin-bottom: 15px;
        border-radius: 4px;
        color: #c63333;
        font-size: 28px;
        text-wrap: nowrap;
      }

      .upload-progress {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90%;

        b {
          color: #000;
        }
      }

      span {
        position: relative;
        width: 90%;
      }

      button {
        margin-top: 30px;
        margin-bottom: 15px;
      }
    }
  }
}