.edit-submission-container {
  position: relative;
  width: 90%;
  max-width: 1050px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 120px;
  border-radius: 4px;
  min-height: 1000px;

  h1 {
    margin: 0;
    margin-bottom: 15px;
  }


  h3 {
    margin-top: 60px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .delete-button {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .edit-submission-content {
    position: relative;
    width: 90%;
    height: auto;
    min-height: 600px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 60px;

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      width: 100%;
    }

    .edit-submission-content-section {
      position: relative;
      width: 50%;
      min-height: 100%;
      height: auto;
      padding: 15px 30px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      b {
        margin-bottom: 10px;
      }

      input {
        font-size: 16px;
        border: 1px solid #ddd;
      }

      textarea {
        font-size: 16px;
        border: 1px solid #ddd;
        height: 200px;
      }
    }

    .section-left {
      width: 40%;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }

    .section-right {
      width: 60%;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      .submission-category-select {
        margin-top: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }

      .submission-links {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #ddd;

        
        ul.link-list {
          position: relative;
          width: 100%;
          height: auto;
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          li {
            position: relative;
            width: 100%;
            height: 30px;
            border-radius: 4px;
            margin-bottom: 10px;
            font-size: 16px;
            background-color: #fff;
            border: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            box-sizing: border-box;
            white-space: nowrap;
            
            .link-key {
              font-weight: 600;
              margin-right: 30px;
            }

            a.link-value {
              text-decoration: unset;
              color: #3262db;
              // overflow: hidden;
            }

            a.link-value:hover {
              text-decoration: underline;
            }

          }

        }

        button {
          margin-top: 15px;
        }
      }

      .submission-team {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #ddd;

        ul.team-list {
          position: relative;
          width: 100%;
          height: auto;
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          li {
            position: relative;
            width: 100%;
            height: 30px;
            border-radius: 4px;
            margin-bottom: 10px;
            font-size: 16px;
            background-color: #fff;
            border: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            box-sizing: border-box;
          }

        }

        button {
          margin-top: 15px;
        }
      }

      .edit-submission-footer {
        position: relative;
        bottom: 0px;
        width: 100%;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #ddd;
        padding-top: 30px;

    
        button {
          margin-left: 10px;
        }
    
      }

    }



  }

}