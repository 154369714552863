.back-button-container {
  position: relative;
  width: 95%;
  height: 30px;
  .back-button {
    position: absolute;
    left: 0px;
    height: 30px;
    width: 90px;
    cursor: pointer;
    color: #0090F2;
    font-weight: 600;
    background-image: url("../../../assets/back-arrow.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 25%;
    .back-title {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }

  @media (max-width: 900px) {
    margin-bottom: 40px;;
  }
}