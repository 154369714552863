.public-profile-container {
  position: relative;
  width: 100%;
  max-width: 850px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 120px;
  background-color: #fff;
  border-radius: 4px;

  .public-profile-container-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // flex-direction: column;
    width: 90%;
    margin-top: 60px;

    @media (max-width: 900px) {
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 500px;
    }

    .user-submissions {
      width: 100%;
      margin-bottom: 20px;

      .submission-header {
        position: relative;
        background-color: #BED962;
        width: 100%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        cursor: pointer;
        margin-bottom: 10px;

        .total-boost-points {
          position: absolute;
          background-color: #fff;
          right: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          height: 30px;
          padding: 0px 15px;

          .boost-icon {
            position: relative;
            width: 20px;
            height: 20px;
            background-image: url("../../assets/boost-rocket-blue.svg"); 
            background-position: center;
            background-repeat: no-repeat;
            background-size: 55%;
          }
  
          .boost-points {
            position: relative;
            color: #0f0f12;
            font-size: 14px;
            font-weight: 600;
            margin-left: 10px;
          }
        }

        h3 {
          position: relative;
          font-size: 14px;
          margin: 0px;
          background-color: #0f0f12;
          color: #fff;
          padding: 7px 18px;
          display: inline-block;
          border-radius: 30px;
          margin-left: 6px;
          cursor: pointer;
          &:hover {
            color: #3262db;
            background-color: #fff;
          }
        }
      }

    }

    .user-profile-image-container {
      position: relative;
      width: 250px;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border: 1px solid #ddd;
      left: 0px;
      border-radius: 4px;
      overflow: hidden;

      .user-profile-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 2;
      }

      .user-profile-image-default {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/user-icon-square.svg");
        background-position: center;
        background-size: 60%;
        z-index: 1;
      }

    }

    .about-profile {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: 40px;
      width: 480px;

      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 30px;
      }

      b {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        text-align: left;
      }

      @media (max-width: 900px) {
        margin-left: 0px;
        margin-top: 10px;
        width: 90%;
        
        h1, b {
          width: 100%;
          text-align: center;
        }

        h1 {
          font-size: 25px;
        }

        p {
          width: 100%;
          text-align: left;
          margin-bottom: 60px;
        }

      }

    }


  }

}