.compare-container {
  position: relative;
  width: 90%;
  max-width: 1400px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 200px;
  min-height: 1200px;

  .confirm-save-filter-popup {
    input {
      max-width: 200px;
      margin-top: 15px;
      margin-bottom: 30px;
      margin-right: 10px;
      font-size: 16px;
      height: 17px;
    }
  }

  .filters-header {
    width: 100%;
    height: 100px;
    background-color: #F9F9F9;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;

    h1 {
      font-size: 28px;
    }

    @media (max-width: 900px) {
      h1 {
        display: none;
      }
      flex-direction: column;
      height: auto;
    }

    .select-filter-container {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      margin-right: 15px;
      width: 100%;
      @media (max-width: 900px) {
        margin-right: unset;
        margin-bottom: 15px;
        flex-direction: column;
      }
      span {
        margin-right: 10px;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 14px;
      }
      .compare-confirm {
        position: absolute;
        right: 0px;
        top: 15px;
        background-color: #000;
        color: #fff;
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #000;
        cursor: pointer;
        @media (max-width: 900px) {
          position: relative;
        }
        .clear-compare {
          position: absolute;
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          right: 9px;
          top: 7px;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url("../../assets/close-icon-filter.svg");
        }
        &:hover {
          background-color: #0090F2;
          border: 1px solid #0090F2;
          .clear-compare {
            background-image: url("../../assets/close-icon-filter-red.svg");
          }
        }
      }

      .save-compare-filter {
        position: absolute;
        right: 220px;
        top: 15px;
        background-color: #000;
        color: #fff;
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #000;
        cursor: pointer;
        @media (max-width: 900px) {
          position: relative;
          right: unset;
          margin-top: 10px;
        }
        &:hover {
          background-color: #28A745;
          border: 1px solid #28A745;
        }
      }

      .filter-item {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-right: 15px;
        @media (max-width: 900px) {
          margin-bottom: 15px;
        }

        .delete-filter {
          display: inline-block;
          color: #B3261E;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .saved-filters {
        width: 200px;
      }

    }

    select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 16px;
      color: #333;
      outline: none;
      width: 380px;
      transition: border-color 0.3s ease;
      @media (max-width: 900px) {
        width: 350px;
      }
      &:focus {
        border-color: #007bff;
      }
  
      option {
        padding: 10px;
        background-color: #fff;
        color: #333;
      }
    }

    select.category-filter {
      width: 200px;
      @media (max-width: 900px) {
        width: 350px;
      }
    }

  }

  .selected-competition-details {
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 30px;
    margin-bottom: 90px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      position: relative;
      font-size: 48px;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: #007bff;
      }
    }

    span {
      font-size: 28px;
    }

    @media (max-width: 900px) {
      h1 {
        font-size: 28px;
      }

      span {
        font-size: 20px;
      }
    }
  }

  .user-items {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 32px;
    justify-content: center; // Center the grid items when there's extra space
    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 0fr));
    }
    .user-item {
      position: relative;
      background-color: #fff;
      border: 1px solid #ddd;
      height: 520px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 250px; // Ensure a consistent minimum width
      border-radius: 8px;
      overflow: hidden;
      @media (max-width: 600px) {
        margin-bottom: 30px;
      }
      &:hover {
        border: 1px solid #007bff;
      }

      .user-compare-select-container {
        position: absolute;
        top: 220px;
        left: 0px;
        width: 100%;
        height: 35px;
        background-color: #fff;
        z-index: 10;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;

        .compare-toggle {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          left: 12px;
          top: 6px;
          background-color: #eee;
          border: 1px solid #ddd;
          background-position: center;
          background-size: 60%;
          background-repeat: no-repeat;
          cursor: pointer;
        }

        .compare-text {
          position: absolute;
          left: 40px;
          top: 8px;
          font-size: 16px;
          // color: #5d6365;
        }

        .active {
          background-color: #0090F2;
          border: 1px solid #0090F2;
          background-image: url("../../assets/checkmark.svg");
        }
      }


    }
  }
  
  
  
  
}