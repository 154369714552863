.list-voting-panel-container {
  position: relative;
  width: 100%;
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  h3 {
    position: relative;
    border-top: 1px solid #ddd;
    width: 100%;
    padding-top: 30px !important;
    padding-bottom: 10px !important;
  }

  .voting-panel {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
    
    & > * {
      flex: 1 1 calc(20% - 10px);
      max-width: calc(20% - 10px);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  @media (max-width: 1200px) {
    .voting-panel > * {
      flex: 1 1 calc(25% - 10px); // 4 items per row
      max-width: calc(25% - 10px);
    }
  }

  @media (max-width: 992px) {
    .voting-panel > * {
      flex: 1 1 calc(33.33% - 10px); // 3 items per row
      max-width: calc(33.33% - 10px);
    }
  }

  @media (max-width: 768px) {
    .voting-panel > * {
      flex: 1 1 calc(50% - 10px); // 2 items per row
      max-width: calc(50% - 10px);
    }
  }

  @media (max-width: 480px) {
    .voting-panel > * {
      flex: 1 1 100%; // 1 item per row
      max-width: 100%;
    }
  }
  

  .judges-panel {
  }

  .jurors-panel {
  }

  
  h3 {
    margin-bottom: 30px;
  }
}
