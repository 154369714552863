

.upload-submission-files-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;

  .upload-section {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;

    h2 {
      font-size: 24px;
      margin-bottom: 0px;
    }

    p {
      font-size: 16px;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .upload-box-video {
      background-color: #333;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      width: 100%;
      border-radius: 10px;
      position: relative;

      input[type="file"] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
      }
    }

    .popup-video-drop-zone {
      .upload-progress {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        b {
          margin: 10px;
          font-size: 30px;
        }
      }
    }

    .confirm-replace-video-popup {
      h1 {
        margin-bottom: 0px;
      }

      h3 {
        margin-top: 10px;
      }
    }

    .upload-box-file {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #ddd;
      position: relative;
      margin-bottom: 10px;
      width: 100%;

      input[type="file"] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
      }
    }
  }
}
