.profile-container-form {
  position: relative;
  width: 90%;
  max-width: 850px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  border-radius: 4px;

  .profile-container-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    max-width: 550px;
    margin-top: 0px;

    .subscribe-button {
      padding: 5px 15px;
      cursor: pointer;
      border-radius: 16px;
      background-color: #fff;
      border: 1px solid #007bff;
      color: #007bff;
      font-weight: 500;
    }

    .subscribe-button:hover {
      background-color: #007bff;
      color: #fff;
    }

    h1 {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 50px;
      font-size: 24px;
      color: #333;
    }

    .upload-profile-picture {
      position: relative;
      width: 200px;
      height: 200px;
      border-radius: 8px;
      background-color: #eee;
      margin-top: 30px;
      background-image: url('../../assets/badge.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .add-cta {
        position: absolute;
        background-color: #007bff;
        color: #fff;
        border: 1px solid #fff;
        font-weight: 560;
        border-radius: 20px;
        padding: 10px 20px;
        bottom: -15px;
      }
    }

    .image-processing {
      pointer-events: none;
    }
  
    form {
      position: relative;
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 30px;
      margin-top: 60px;
    }
    
    label {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 14px;
    }
    
    input, textarea {
      position: relative;
      width: 100%;
      padding: 8px 0px;
      margin-bottom: 30px;
      border: 1px solid #ccc;
      border-radius: 4px;
      text-indent: 10px;
      height: 30px;
      font-size: 16px;
    }

    textarea {
      text-indent: 0px;
      height: 100px;
      padding: 10px;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    .profile-save-button {
      margin-left: auto;
      margin-top: 10px;
    }

  }

}