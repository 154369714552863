.home-container {
  position: relative;
  width: 90%;
  max-width: 1400px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  min-height: 500px;

  h1 {
    text-transform: capitalize;
    font-size: 28px;
  }

  h2 {
    font-size: 28px;
  }

  .create-cta-container {
    width: 100%;
    height: 150px;
    background-color: #f9f9f9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .trophy-icon {
      position: relative;
      width: 50px;
      height: 50px;
      margin-right: 30px;
      background-image: url("../../assets/trophy-blue.svg");
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
    }

    h2 {
      font-weight: 400;
      font-size: 24px;
      margin-right: 30px;
    }

    @media (max-width: 900px) {
      flex-direction: column;
      height: 200px;

      .trophy-icon {
        position: relative;
        width: 40px;
        height: 40px;
        margin-right: 0px;
      }

      h2 {
        margin-right: 0px;
        font-size: 20px;
        padding-left: 15px;
        padding-right: 15px;
      }

    }
  }

  .upload-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    span {
      position: relative;
      margin-bottom: 15px;
    }

    b {
      font-size: 30px;
    }
  }
  

}