.app-container {
  text-align: center;
  overflow-x: hidden;

  .app-footer {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 200px;
    bottom: 0px;
    z-index: 1000;

    .footer-image {
      width: 200px;
      height: 40px;
      background-image: url('./assets/badge.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .footer-links {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60px;
      border-top: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #999999;
      background-color: #fff;

      ul {
        position: absolute;
        list-style: none;
        display: inline;
        margin: 0;
        padding: 0;
        right: 15px;

        li {
          display: inline;
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          a {
            text-decoration: none;
            color: grey;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .show-mobile {
          display: none;
        }
      }

      ul.left-links {
        right: unset;
        left: 15px;
      }

    }
  }

  @media (max-width: 900px) {
    .app-footer {
      .footer-links {
        position: relative;
        right: unset;
        flex-direction: column-reverse;
        height: 100px;
        margin-top: 30px;

        ul {
          position: static;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 0;

          .show-mobile {
            display: inline-block;
          }

        }

        ul.left-links {
          display: none;
        }

        span {
          margin-top: 10px;
        }
        
      }
    }
  }
}
