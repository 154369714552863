.points-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; // Ensures the container fits within its parent
  box-sizing: border-box; // Includes padding and border in the element's width/height calculations
  padding: 15px; // Padding inside the container
}

.point {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // Vertically center the key names and input fields

  .key-name {
    width: 20%; // Keep key name width at 20%
    text-align: left; // Ensure key names are left-aligned
    font-weight: bold;
    height: 100%;
    height: 25px;
    padding: 5px;
    padding-top: 10px;
  }

  .value-input {
    width: 70%; // Keep input width at 70%
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 3px;
    height: 25px;
  }
}
