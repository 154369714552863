.button {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #0090F2;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: capitalize;

  &:hover {
    background-color: #0284da;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  &.primary {
    background-color: #0090F2;

    &:hover {
      background-color: #0284da;
    }
  }

  &.published {
    background-color: #19ae66;

    &:hover {
      background-color: #169658;
    }
  }

  &.secondary {
    background-color: #e7eaed;
    color: #0090F2;
    // border: 1px solid #007bff;

    &:hover {
      background-color: #ebedef;
    }
  }

  &.success {
    background-color: #28a745;

    &:hover {
      background-color: #218838;
    }
  }

  &.danger {
    background-color: #dc3545;

    &:hover {
      background-color: #c82333;
    }
  }

  &.warning {
    background-color: #ffc107;

    &:hover {
      background-color: #e0a800;
    }
  }

  &.info {
    background-color: #17a2b8;

    &:hover {
      background-color: #138496;
    }
  }

  &.light {
    background-color: #f8f9fa;
    color: #000;

    &:hover {
      background-color: #e2e6ea;
    }
  }

  &.dark {
    background-color: #343a40;

    &:hover {
      background-color: #23272b;
    }
  }
}
