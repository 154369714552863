.manage-team-list-container {
  position: relative;
  width: 90%;
  max-width: 850px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 60px;
  background-color: #fff;
  border-radius: 4px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
    font-size: 24px;
    color: #333;
  }

  span {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    color: #666;
  }

  .user-list {
    position: relative;
    width: 90%;
    max-width: 500px;
    margin-top: 10px;

    .user-row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 10px;

      &.user-row-header {
        font-weight: bold;
        background-color: #eee;
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 20px;
        
        .email-header {
          flex: 1.7;
          text-align: left;
        }

        .role-header {
          flex: 1;
          text-align: left;
        }
      }
  
      input[type="email"] {
        flex: 3;
        padding: 8px;
        margin-right: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
      }
  
      select {
        flex: 1;
        padding: 8px;
        margin-right: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        height: 34px;
      }
  
      button {
        position: relative;
        top: 0px;
        padding: 8px 12px;
        background-color: #b1afaf;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        margin: 0px;
        height: 34px;
  
        &:hover {
          background-color: #838383;
        }
      }
    }

    .footer-buttons {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .save-button {
        margin-left: 10px;
      }
    }
  }

  .add-user-button {
    background-color: #fff;
    border: 1px solid #3262db;
    color: #3262db;
    height: 34px;
  }

  .add-user-button:hover {
    background-color: #dfe4f1;
  }

  .error {
    margin-top: 20px;
    color: #e74c3c;
    text-align: center;
  }

  p {
    text-align: center;
  }
}
