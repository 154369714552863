.user-card {
  position: relative;
  width: 140px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;

  .user-card-image {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    background-image: url("../../../assets/user-icon-square.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .user-card-name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}

.user-display-row {
  position: relative;
  width: 100%;
  height: 60px;
  // background-color: #fff;
  // border: 1px solid #ddd;
  cursor: pointer;

  border-radius: 4px;
  margin-bottom: 10px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // padding: 10px;
  box-sizing: border-box;

  .user-card-image {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: #eee;
    background-image: url("../../../assets/user-icon-square.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid #ddd;
    left: 0px;
    border-radius: 50%;
  }

  .user-card-name {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
  }
}