.multi-select {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
}

.option {
  padding: 5px 10px;
  border: 1px solid #ddd;
  margin: 2px;
  cursor: pointer;
  border-radius: 30px;
  background-color: #fff;
}

.option.selected {
  background-color: #fff;
  border: 1px solid #0090F2;
  color: #0090F2;
}
