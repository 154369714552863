.compete-container {
  position: relative;
  width: 90%;
  max-width: 1400px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 200px;
  min-height: 1200px;

  .filters-header {
    width: 100%;
    height: 100px;
    background-color: #F9F9F9;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;

    h1 {
      font-size: 28px;
    }

    @media (max-width: 900px) {
      h1 {
        display: none;
      }
      flex-direction: column;
      height: auto;
    }

    .select-filter-container {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      margin-right: 15px;
      width: 100%;
      @media (max-width: 900px) {
        margin-right: unset;
        margin-bottom: 15px;
        flex-direction: column;
      }
      span {
        margin-right: 10px;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 14px;
      }
      .filter-item {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-right: 15px;
        @media (max-width: 900px) {
          margin-bottom: 15px;
        }

        .delete-filter {
          display: inline-block;
          color: #B3261E;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  
    select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 16px;
      color: #333;
      outline: none;
      width: 380px;
      transition: border-color 0.3s ease;
  
      &:focus {
        border-color: #007bff;
      }
  
      option {
        padding: 10px;
        background-color: #fff;
        color: #333;
      }
    }
  }

  .selected-competition-details {
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      position: relative;
      cursor: pointer;
      width: auto;
      font-size: 48px;
      margin-bottom: 10px;
      &:hover {
        text-decoration: underline;
        color: #007bff;
      }
    }

    span {
      font-size: 28px;
    }

    @media (max-width: 900px) {
      h1 {
        font-size: 28px;
      }

      span {
        font-size: 20px;
      }
    }
  }

  h3 {
    text-align: center;
    width: 100%;
  }

  .sponsors-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 25px;
    padding: 20px;
  
    .sponsor-item {
      width: 200px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
}