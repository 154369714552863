.invite-competition-container {
  position: relative;
  width: 90%;
  max-width: 850px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 120px;
  background-color: #fff;
  border-radius: 4px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
    font-size: 24px;
    color: #333;
  }

  .popup-container {
    p {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .user-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; // Center items in each row
    gap: 10px; // Adjust the gap between items as needed
    margin-bottom: 30px;
    
    & > * {
      flex: 1 1 calc(20% - 10px); // Make each item take 20% of the row width, accounting for the gap
      max-width: calc(20% - 10px);
      box-sizing: border-box;
      display: flex;
      justify-content: center; // Center content horizontally within the item
      align-items: center; // Center content vertically within the item
    }
  }


  @media (max-width: 1200px) {
    .user-container > * {
      flex: 1 1 calc(25% - 10px); // 4 items per row
      max-width: calc(25% - 10px);
    }
  }

  @media (max-width: 992px) {
    .user-container > * {
      flex: 1 1 calc(33.33% - 10px); // 3 items per row
      max-width: calc(33.33% - 10px);
    }
  }

  @media (max-width: 768px) {
    .user-container > * {
      flex: 1 1 calc(50% - 10px); // 2 items per row
      max-width: calc(50% - 10px);
    }
  }

  @media (max-width: 480px) {
    .user-container > * {
      flex: 1 1 100%; // 1 item per row
      max-width: 100%;
    }
  }
  

  .user-container-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    padding: 30px 0px;
    margin: 0 auto;
  }

  span {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    color: #666;
  }

  .user-list {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .section {
    margin: 20px 0;
    padding: 20px 0px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
  }
  
  .competitors {
    background-color: #f9f9f9;
    border: 1px solid #0056b3;
    border-radius: 8px;

    .user-display-row {
      background-color: #fff;
      border: 1px solid #ddd;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
    }
  }

  p {
    text-align: center;
  }
}
