.user-card-complex {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .user-card-image-container {
    position: relative;
    width: 100%;
    height: 250px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }

  .user-card-image-default, .user-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/user-icon-square.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    z-index: 1;
    background-size: 50%;
  }

  .user-card-image {
    z-index: 2;
    background-size: cover;
  }

  .user-card-name {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 18px;
    text-align: left;
    width: 90%;
    cursor: pointer;
    &:hover {
      color: #007bff;
    }
  }

  .user-bio {
    position: relative;
    text-align: left;
    width: 90%;
    height: 145px;
    overflow: hidden;
    font-size: 16px;
    line-height: 130%;
    margin-top: 24px;
    border-bottom: 1px solid #ddd;
    pointer-events: none;
  }

  .user-submission-items {
    position: relative;
    width: 90%;
    margin-top: 15px;

    .user-submission {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      cursor: pointer;
      margin-bottom: 10px;

      .submission-name {
        font-size: 16px;
        width: 60%;
        text-align: left;
        margin-left: 10px;
      }

      .total-boost-points {
        position: relative;
        background-color: #0090F2;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        height: 35px;
        padding: 0px 10px;
        width: 40%;
        max-width: 80px;
    
        .boost-icon {
          position: absolute;
          width: 25px;
          height: 25px;
          background-image: url("../../../assets/boost-rocket.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
          left: 10px;
        }
    
        .boost-points {
          position: absolute;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          right: 10px;
          text-wrap: nowrap;
        }
      }

      &:hover {
        .submission-name {
          color: #007bff;
        }
      }
    }

  }
}